import moment from "moment";

moment.locale("fr");

export const format_date_string = (date: string): string => {
  return moment.utc(date).format("D MMMM YYYY");
};

export const format_date_full = (date: string): string => {
  return moment.utc(date).format("dddd Do MMMM YYYY");
};

export const format_date = (date: string): string => {
  return moment.utc(date).format("DD/MM/YYYY");
};

export const format_date_time = (date: string): string => {
  return moment.utc(date).format("dddd Do MMMM YYYY, HH:mm");
};

export const format_time = (date: string): string => {
  return moment.utc(date).format("HH:mm");
};
