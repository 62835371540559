<template>
  <div class="dropdown">
    <button
      class="btn btn-light edit-button"
      type="button"
      id="dropdownMenuButton"
      data-bs-toggle="dropdown"
    >
      ...
    </button>
    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
      <li>
        <button type="button" class="dropdown-item" @click="$emit('edit')">
          <font-awesome-icon icon="pen" /> Modifier
        </button>
      </li>
      <li>
        <button type="button" class="dropdown-item" @click="$emit('delete')">
          <font-awesome-icon icon="trash" /> Supprimer
        </button>
      </li>
    </ul>
  </div>
</template>
